import type { FunctionalComponent } from 'preact';

import { useRemainingTime } from './block-switch-to-tech-timer-banner-1.hooks/use-remaining-time.js';
import { useScrollReachedEnd } from './block-switch-to-tech-timer-banner-1.hooks/use-scroll-reached-end.js';
import type { BlockSwitchToTechTimerBanner1Props } from './block-switch-to-tech-timer-banner-1.js';
import { BlockSwitchToTechTimerBanner1 } from './block-switch-to-tech-timer-banner-1.js';

export type BlockSwitchToTechTimerBanner1ContainerProps = Omit<
    BlockSwitchToTechTimerBanner1Props,
    'hidden' | 'days' | 'hours' | 'mins' | 'secs' | 'onCloseButtonClick'
> & {
    endDate: string | undefined;
};

export const BlockSwitchToTechTimerBanner1Container: FunctionalComponent<
    BlockSwitchToTechTimerBanner1ContainerProps
> = ({ endDate, ...props }) => {
    const { days, hours, mins, secs, ended } = useRemainingTime(endDate);
    const reachedEnd = useScrollReachedEnd();

    return (
        <BlockSwitchToTechTimerBanner1
            hidden={ended || days >= 10 || reachedEnd}
            days={days}
            hours={hours}
            mins={mins}
            secs={secs}
            {...props}
        />
    );
};
