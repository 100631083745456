import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__button.css';

export interface BlockSwitchToTechTimerBanner1ButtonProps {
    children?: ComponentChildren;
    link?: string;
    onClick?: VoidFunction;
}

export const BlockSwitchToTechTimerBanner1Button: FunctionalComponent<BlockSwitchToTechTimerBanner1ButtonProps> = ({
    children,
    link,
}) => (
    <a
        href={link}
        id="switch-to-tech-timer-banner-button"
        target={link?.startsWith('#') ? undefined : '_blank'}
        rel="nofollow noreferrer"
        className={cnBlockSwitchToTechTimerBanner1('button')}
    >
        {children}
    </a>
);
