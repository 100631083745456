import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__text.css';

export interface BlockSwitchToTechTimerBanner1TextProps {
    children?: ComponentChildren;
}

export const BlockSwitchToTechTimerBanner1Text: FunctionalComponent<BlockSwitchToTechTimerBanner1TextProps> = ({
    children,
}) => <span className={cnBlockSwitchToTechTimerBanner1('text')}>{children}</span>;
