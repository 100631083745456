import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__caption.css';

export interface BlockSwitchToTechTimerBanner1CaptionProps {
    children?: ComponentChildren;
}

export const BlockSwitchToTechTimerBanner1Caption: FunctionalComponent<BlockSwitchToTechTimerBanner1CaptionProps> = ({
    children,
}) => <div className={cnBlockSwitchToTechTimerBanner1('caption')}>{children}</div>;
