import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__highlighted-text.css';

export interface BlockSwitchToTechTimerBanner1HighlightedTextProps {
    children?: ComponentChildren;
}

export const BlockSwitchToTechTimerBanner1HighlightedText: FunctionalComponent<
    BlockSwitchToTechTimerBanner1HighlightedTextProps
> = ({ children }) => <span className={cnBlockSwitchToTechTimerBanner1('highlighted-text')}>{children}</span>;
