import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__value.css';

export interface BlockSwitchToTechTimerBanner1ValueProps {
    children?: ComponentChildren;
}

export const BlockSwitchToTechTimerBanner1Value: FunctionalComponent<BlockSwitchToTechTimerBanner1ValueProps> = ({
    children,
}) => <div className={cnBlockSwitchToTechTimerBanner1('value')}>{children}</div>;
