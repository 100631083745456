import type { FunctionalComponent } from 'preact';

import { BlockSwitchToTechTimerBanner1Label } from '../__label/block-switch-to-tech-timer-banner-1__label.js';
import { BlockSwitchToTechTimerBanner1Value } from '../__value/block-switch-to-tech-timer-banner-1__value.js';
import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__cell.css';

export interface BlockSwitchToTechTimerBanner1CellProps {
    value: number;
    label: string;
}

export const BlockSwitchToTechTimerBanner1Cell: FunctionalComponent<BlockSwitchToTechTimerBanner1CellProps> = ({
    value,
    label,
}) => (
    <div className={cnBlockSwitchToTechTimerBanner1('cell')}>
        <BlockSwitchToTechTimerBanner1Value>{String(value).padStart(2, '0')}</BlockSwitchToTechTimerBanner1Value>
        <BlockSwitchToTechTimerBanner1Label>{label}</BlockSwitchToTechTimerBanner1Label>
    </div>
);
