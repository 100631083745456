import icon from './icon.png';
import type { FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__icon.css';

export interface BlockSwitchToTechTimerBanner1IconProps {}

export const BlockSwitchToTechTimerBanner1Icon: FunctionalComponent<BlockSwitchToTechTimerBanner1IconProps> = () => (
    <img src={icon.src} className={cnBlockSwitchToTechTimerBanner1('icon')} />
);
