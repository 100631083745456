import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__countdown.css';

export interface BlockSwitchToTechTimerBanner1CountdownProps {
    children?: ComponentChildren;
}

export const BlockSwitchToTechTimerBanner1Countdown: FunctionalComponent<
    BlockSwitchToTechTimerBanner1CountdownProps
> = ({ children }) => <div className={cnBlockSwitchToTechTimerBanner1('countdown')}>{children}</div>;
