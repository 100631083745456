import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__title.css';

export interface BlockSwitchToTechTimerBanner1TitleProps {
    children?: ComponentChildren;
}

export const BlockSwitchToTechTimerBanner1Title: FunctionalComponent<BlockSwitchToTechTimerBanner1TitleProps> = ({
    children,
}) => <span className={cnBlockSwitchToTechTimerBanner1('title')}>{children}</span>;
