import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockSwitchToTechTimerBanner1 } from '../block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1__common-text.css';

export interface BlockSwitchToTechTimerBanner1CommonTextProps {
    children?: ComponentChildren;
}

export const BlockSwitchToTechTimerBanner1CommonText: FunctionalComponent<
    BlockSwitchToTechTimerBanner1CommonTextProps
> = ({ children }) => <span className={cnBlockSwitchToTechTimerBanner1('common-text')}>{children}</span>;
