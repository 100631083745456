import type { FunctionalComponent } from 'preact';

import { BlockSwitchToTechTimerBanner1Button } from './__button/block-switch-to-tech-timer-banner-1__button.js';
import { BlockSwitchToTechTimerBanner1Caption } from './__caption/block-switch-to-tech-timer-banner-1__caption.js';
import { BlockSwitchToTechTimerBanner1Cell } from './__cell/block-switch-to-tech-timer-banner-1__cell.js';
import { BlockSwitchToTechTimerBanner1CommonText } from './__common-text/block-switch-to-tech-timer-banner-1__common-text.js';
import { BlockSwitchToTechTimerBanner1Countdown } from './__countdown/block-switch-to-tech-timer-banner-1__countdown.js';
import { BlockSwitchToTechTimerBanner1HighlightedText } from './__highlighted-text/block-switch-to-tech-timer-banner-1__highlighted-text.js';
import { BlockSwitchToTechTimerBanner1Icon } from './__icon/block-switch-to-tech-timer-banner-1__icon.js';
import { BlockSwitchToTechTimerBanner1Text } from './__text/block-switch-to-tech-timer-banner-1__text.js';
import { BlockSwitchToTechTimerBanner1Title } from './__title/block-switch-to-tech-timer-banner-1__title.js';
import { cnBlockSwitchToTechTimerBanner1 } from './block-switch-to-tech-timer-banner-1.constants.js';

import './block-switch-to-tech-timer-banner-1.css';

import './_hidden/block-switch-to-tech-timer-banner-1_hidden.css';

type BlockAttributes = {
    hidden: boolean;

    buttonText: string | undefined;
    buttonLink: string | undefined;

    text: string | undefined;
    highlightedText: string | undefined;

    days: number;
    hours: number;
    mins: number;
    secs: number;

    preTitle: string | undefined;
    postTitle: string | undefined;
};

export interface BlockSwitchToTechTimerBanner1Props extends BlockAttributes {}

export const BlockSwitchToTechTimerBanner1: FunctionalComponent<BlockSwitchToTechTimerBanner1Props> = ({
    buttonText,
    buttonLink,
    text,
    highlightedText,
    hidden,
    days,
    hours,
    mins,
    secs,
    preTitle,
    postTitle,
}) => (
    <div className={cnBlockSwitchToTechTimerBanner1({ hidden })}>
        <BlockSwitchToTechTimerBanner1Caption>
            <BlockSwitchToTechTimerBanner1Title>
                {preTitle}
                <BlockSwitchToTechTimerBanner1Icon />
                {postTitle}
            </BlockSwitchToTechTimerBanner1Title>
            <BlockSwitchToTechTimerBanner1Text>
                <BlockSwitchToTechTimerBanner1HighlightedText>
                    {highlightedText}
                </BlockSwitchToTechTimerBanner1HighlightedText>{' '}
                <BlockSwitchToTechTimerBanner1CommonText>{text}</BlockSwitchToTechTimerBanner1CommonText>
            </BlockSwitchToTechTimerBanner1Text>
        </BlockSwitchToTechTimerBanner1Caption>
        <BlockSwitchToTechTimerBanner1Countdown>
            <BlockSwitchToTechTimerBanner1Cell value={days} label="d" />
            <BlockSwitchToTechTimerBanner1Cell value={hours} label="h" />
            <BlockSwitchToTechTimerBanner1Cell value={mins} label="m" />
            <BlockSwitchToTechTimerBanner1Cell value={secs} label="s" />
        </BlockSwitchToTechTimerBanner1Countdown>
        <BlockSwitchToTechTimerBanner1Button link={buttonLink}>{buttonText}</BlockSwitchToTechTimerBanner1Button>
    </div>
);
